<template>
  <div>Venue</div>
</template>

<script>
export default {
  name: "Venue",
  beforeMount(){
    const page = this.$store.state.epitch.pageTree.filter(page => page.id == 3)[0]
    this.$router.push(page.children[0].route)
  }
};
</script>

<style>
</style>